import iziModal from 'izimodal/js/iziModal.js';
$.fn.iziModal = iziModal;

$(function(){
  if (!(Util.controller_is('contacts'))) {
    return false;
  }

  const $form = $('form#new_contact');
  $form.validate({
    errorPlacement: function(error, element) {
      error.insertAfter(
        $(element).parents('.entryForm__inputContainer, .entryForm__selectContainer, .entryForm__agreementContainer:first'));
    },
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids()) {
        return;
      }
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top
      }, 500);
    },
      errorClass: "entryForm__error",
      errorElement: "div"
  });
  $.extend($.validator.messages, {
    required: 'この項目は必須です。',
  });

  const $entryFormModal = $('.entryFormModal');
  $entryFormModal.iziModal({
    headerColor: '#23334B',
    closeButton: true,
    radius: 3,
    borderBottom: false,
    padding: 0
  });

  $('.js-open-modal').click(function(e){
    e.preventDefault();
    if(!$form.valid()) {
      return false;
    }

    [...Array(3)].map(function(_e, index) {
      const num = index + 1;
      const fieldName = 'field' + num;
      const $filed = $('input.' + fieldName + ',select.' + fieldName + ',textarea.' + fieldName);
      let val = $filed.val().replace(/\r?\n/g, '<br />');

      if (!val) {
        val = '入力なし';
      }

      $('.entryFormModal__dd.' + fieldName).html(val);
    });

    $entryFormModal.iziModal('open');
  });

  $('.js-submit').click(function(){
    $form.submit();
  });
});
